<template>
  <v-container>
    <v-app style="background-color: #eeeeee;">
      <v-container>
        <v-row>
          <v-col cols="12">
            <base-material-card color="primary">
              <template v-slot:heading>
                <div class="text-h3 font-weight-light">Reviews</div>
              </template>
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="users.data"
                  :server-items-length="tableOptions.totalItems"
                  :options.sync="tableOptions"
                  :loading="loading"
                  :footer-props="{
                    'items-per-page-options': tableOptions.rowsPerPageItems,
                  }"
                >
                  <template v-slot:[`item.feedback`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          {{ getShortFeedback(item.feedback) }}
                        </div>
                      </template>
                      <span>{{ item.feedback }}</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.reviewer_name`]="{ item }">
                    <v-tooltip v-if="item.reviewerRole == 'driver'" top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on" @click="goToDriverProfile(item.reviewerId)">
                          {{ item.reviewer_name }}
                        </div>
                      </template>
                      <span>{{ "Click to view profile." }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.reviewerRole == 'customer'" top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on" @click="goToCustomerProfile(item.reviewerId)">
                          {{ item.reviewer_name }}
                        </div>
                      </template>
                      <span>{{ "Click to view profile." }}</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.reviewee_name`]="{ item }">
                    <v-tooltip v-if="item.revieweeRole == 'driver'" top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on" @click="goToDriverProfile(item.revieweeId)">
                          {{ item.reviewee_name }}
                        </div>
                      </template>
                      <span>{{ "Click to view profile." }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.revieweeRole == 'customer'" top>
                      <template v-slot:activator="{ on }">
                        <div v-on="on" @click="goToCustomerProfile(item.revieweeId)">
                          {{ item.reviewee_name }}
                        </div>
                      </template>
                      <span>{{ "Click to view profile." }}</span>
                    </v-tooltip>
                  </template>


                  <template v-slot:[`item.rating`]="{ item }">
                    <!-- {{ item.Booking }} -->
                    <template>
                      <div class="text-left justify-start">
                        <v-rating
                          v-model="item.rating"
                          background-color="orange lighten-3"
                          color="orange"
                          size="18"
                          dense
                          half-increments
                          readonly
                          style="display: inline;"
                        ></v-rating>
                        ({{ item.rating }})
                      </div>
                    </template>
                  </template>
                </v-data-table>
              </v-card>
              <booking-modal
                v-if="bookingModalShow"
                :open="bookingModalShow"
                :data="bookingData"
                @close="closeBookingModal"
              />
            </base-material-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </v-container>
</template>
<script>
import { getUsersReviews } from '@/services/users'
import { getBooking } from '@/services/admin'
import DateFilter from '@/components/dateFilter'
import bookingModal from '@/components/bookingModal'

export default {
  components: { bookingModal, DateFilter },
  data() {
    return {
      stopTable: 0,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'reviewId',
        },
        {
          text: 'Booking ID',
          align: 'start',
          sortable: false,
          value: 'bookingId',
        },
        {
          text: 'Reviewer Name',
          align: 'start',
          sortable: false,
          value: 'reviewer_name',
        },
        {
          text: 'Reviewee Name',
          align: 'start',
          sortable: false,
          value: 'reviewee_name',
        },
        {
          text: 'Rating',
          align: 'start',
          sortable: false,
          value: 'rating',
          width: '200px',
        },
        {
          text: 'Comments',
          align: 'start',
          sortable: false,
          value: 'feedback',
        },
      ],
      users: {
        data: [],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: true,
      bookingModalShow: false,
      bookingData: null,
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.loading = true
        const _self = this
        setTimeout(function () {
          _self.getUsersData()
        }, 500)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getUsersData()
  },
  methods: {
    async getUsersData() {
      this.loading = true
      const params = {
        search: this.searchKeyword,
        limit: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
      };
      console.log('data is being set')
      getUsersReviews(params).then(async (res) => {
        this.users.data = []
        let responsData = []
        responsData = res.data.reviews
        this.tableOptions.totalItems = res.data.count;
        await responsData.forEach((ele) => {
          const row = {
            reviewId: this.getShortId(ele?._id),
            bookingId: ele?.requestId
              ? this.getShortId(ele?.requestId?._id)
              : 'No Booking Found',
            reviewer_name: ele?.reviewer_id
              ? ele?.reviewer_id?.firstName +
                ' ' +
                ele?.reviewer_id?.surName +
                '(' +
                ele?.reviewer_id?.role +
                ')'
              : 'No Reviewer Found',
            reviewerId: ele?.reviewer_id?._id,
            reviewerRole: ele?.reviewer_id?.role,
            reviewee_name: ele?.reviewee_id
              ? ele?.reviewee_id?.firstName +
                ' ' +
                ele?.reviewee_id?.surName +
                '(' +
                ele?.reviewee_id?.role +
                ')'
              : 'No Reviewee Found',
            revieweeId: ele?.reviewee_id?._id,
            revieweeRole: ele?.reviewee_id?.role,
            rating: ele?.rating,
            feedback: ele?.feedback ? ele?.feedback : 'No Comments',
          }
          // console.log("this=>", row.reviewerId, row);
          this.users.data.push(row)
          this.loading = false
        })
        console.log('this.tableOptions.totalItems', this.tableOptions.totalItems);

      })
    },
    showBooking(booking_id) {
      let booking = {
        id: booking_id,
      }
      this.bookingModalShow = true
      this.bookingData = booking
    },
    closeBookingModal() {
      this.bookingModalShow = false
      this.bookingData = null
    },
    getShortFeedback(feedback) {
      console.log('get short feedback is called')
      return feedback.length > 12 ? `${feedback?.slice(0, 12)}...` : feedback
    },
    getShortId(id) {
      if(!id) return ''
      return id.substring(id.length - 5)
    },
    goToDriverProfile(id) {
      this.$router.push({ name: 'Driver Profile', params: { id: id } })
    },
    goToCustomerProfile(id) {
      this.$router.push({ name: "Customer Profile", params: { id: id } });
    },
  },
}
</script>
